import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDrugs(ctx, data) {
      return useJwt.getDrugs(data).then(response => response);
    },
    createDrug(ctx, data) {
      return useJwt.createDrug(data).then(response => response);
    },
    updateDrug(ctx, data) {
      return useJwt.updateDrug(data).then(response => response);
    },
    deleteDrug(ctx, id) {
      return useJwt.deleteDrug(id).then(response => response);
    },
  },
};
