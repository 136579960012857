exports.drugUnits = () => [
  { title: 'Viên', value: 'pill' },
  { title: 'Ống/Tuýt', value: 'tube' },
  { title: 'Lọ', value: 'vial' },
  { title: 'Chai', value: 'bottle' },
  { title: 'Bình xịt', value: 'spray' },
  { title: 'Gói', value: 'package' },
  { title: 'Túi', value: 'bag' },
  { title: 'Ống tiêm', value: 'syringe' },
  { title: 'Bút tiêm', value: 'injector_pen' },
  { title: 'Khác', value: 'other' },
];
